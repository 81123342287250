import { LooseObject, P, prettyBytes } from '@piccolohealth/util';

export type PiccoloErrorType =
  | 'Auth0UserIdNotFound'
  | 'BillingActionNotFound'
  | 'BillingProductNotFound'
  | 'BillingSubscriptionItemNotFound'
  | 'BillingUnprocessable'
  | 'FileTooLarge'
  | 'FileEmpty'
  | 'Forbidden'
  | 'FormDecodeResultUnprocessable'
  | 'FormNotFound'
  | 'IncidentNotFound'
  | 'IncidentFileNotFound'
  | 'InternalClientError'
  | 'InternalServerError'
  | 'InvalidUserRoleCombination'
  | 'InvoiceInvalid'
  | 'LabelNotFound'
  | 'OrganizationNotFound'
  | 'ParticipantBehaviourMethodNotFound'
  | 'ParticipantBehaviourMethodNotActive'
  | 'ParticipantBehaviourNotActive'
  | 'ParticipantBehaviourNotFound'
  | 'ParticipantBehaviourRecordingNotFound'
  | 'ParticipantBehaviourRecordingMethodNotFound'
  | 'ParticipantBehaviourRecordingUserNotFound'
  | 'ParticipantDocumentNotFound'
  | 'ParticipantDocumentVersionNotFound'
  | 'ParticipantExportError'
  | 'ParticipantKeyContactNotFound'
  | 'ParticipantLabelNotFound'
  | 'ParticipantFileDuplicateName'
  | 'ParticipantFileNotFound'
  | 'ParticipantFieldNotFound'
  | 'ParticipantFieldTemplateNotFound'
  | 'ParticipantFieldGroupNotFound'
  | 'ParticipantFieldGroupTemplateNotFound'
  | 'ParticipantNotActive'
  | 'ParticipantNotFound'
  | 'ParticipantNoteNotFound'
  | 'ParticipantNoteVersionNotFound'
  | 'ParticipantQrNotActive'
  | 'ParticipantQrNotFound'
  | 'ParticipantQrBehaviourNotFound'
  | 'ParticipantUserNotFound'
  | 'RoleNotFound'
  | 'StorageUploadInvalidDataType'
  | 'TemplateNotFound'
  | 'TemplateVersionNotFound'
  | 'IncorrectTemplateType'
  | 'Unauthorized'
  | 'UserNotFound'
  | 'UserOrganizationNotFound';

export interface PiccoloErrorProps {
  type: PiccoloErrorType;
  message: string;
  stack?: string;
  metadata?: LooseObject;
}

export class PiccoloError extends Error {
  type: PiccoloErrorType;
  message: string;
  statusCode: number;
  stack?: string;
  metadata?: LooseObject;

  constructor(response: PiccoloErrorProps) {
    const { type, message, stack, metadata } = response;
    super();

    this.name = type;
    this.type = type;
    this.message = message;
    this.statusCode = PiccoloError.getStatusCode(type);
    this.stack = stack ?? this.stack;
    this.metadata = metadata;
  }

  static getStatusCode(type: PiccoloErrorType): number {
    switch (type) {
      case 'FileEmpty':
      case 'InvalidUserRoleCombination':
      case 'InvoiceInvalid':
      case 'StorageUploadInvalidDataType':
      case 'IncorrectTemplateType':
        return 400;
      case 'Unauthorized':
        return 401;
      case 'Forbidden':
      case 'ParticipantNotActive':
      case 'ParticipantBehaviourNotActive':
      case 'ParticipantBehaviourMethodNotActive':
      case 'ParticipantQrNotActive':
        return 403;
      case 'Auth0UserIdNotFound':
      case 'BillingActionNotFound':
      case 'BillingProductNotFound':
      case 'BillingSubscriptionItemNotFound':
      case 'FormNotFound':
      case 'IncidentNotFound':
      case 'IncidentFileNotFound':
      case 'LabelNotFound':
      case 'OrganizationNotFound':
      case 'ParticipantBehaviourMethodNotFound':
      case 'ParticipantBehaviourNotFound':
      case 'ParticipantBehaviourRecordingMethodNotFound':
      case 'ParticipantBehaviourRecordingNotFound':
      case 'ParticipantBehaviourRecordingUserNotFound':
      case 'ParticipantDocumentNotFound':
      case 'ParticipantDocumentVersionNotFound':
      case 'ParticipantFileNotFound':
      case 'ParticipantFieldNotFound':
      case 'ParticipantFieldTemplateNotFound':
      case 'ParticipantFieldGroupNotFound':
      case 'ParticipantFieldGroupTemplateNotFound':
      case 'ParticipantKeyContactNotFound':
      case 'ParticipantLabelNotFound':
      case 'ParticipantNotFound':
      case 'ParticipantNoteVersionNotFound':
      case 'ParticipantNoteNotFound':
      case 'ParticipantQrNotFound':
      case 'ParticipantQrBehaviourNotFound':
      case 'ParticipantUserNotFound':
      case 'RoleNotFound':
      case 'TemplateNotFound':
      case 'TemplateVersionNotFound':
      case 'UserNotFound':
      case 'UserOrganizationNotFound':
        return 404;
      case 'ParticipantFileDuplicateName':
        return 409;
      case 'FileTooLarge':
        return 413;
      case 'BillingUnprocessable':
      case 'FormDecodeResultUnprocessable':
        return 422;
      case 'ParticipantExportError':
      case 'InternalClientError':
      case 'InternalServerError':
        return 500;
      default:
        // Will error when type has not been exhaustively matched
        return P.exhaustiveMatchingGuard(type);
    }
  }
}

export const renderPiccoloError = (error: PiccoloError) => {
  switch (error.type) {
    case 'Unauthorized':
      return {
        type: 'Unauthorized',
        message: 'Sorry, you are not authorized to access this resource',
      };
    case 'FileEmpty': {
      return {
        type: 'File Empty',
        message: 'Sorry, the file you are trying to upload is empty',
      };
    }
    case 'FileTooLarge': {
      const limit = error.metadata?.limit;
      const message = P.compact([
        'Sorry, the file you are trying to upload is too large',
        limit && `Please ensure it is < ${prettyBytes(limit)}`,
      ]).join('. ');

      return {
        type: 'File Too Large',
        message,
      };
    }
    case 'Forbidden':
      return {
        type: 'Forbidden',
        message: 'Sorry, you are not permitted to access this resource',
      };
    case 'TemplateNotFound':
    case 'TemplateVersionNotFound': {
      return {
        type: 'Template Not Found',
        message: 'Sorry, we could not find the template you were looking for',
      };
    }
    case 'FormNotFound': {
      return {
        type: 'Form Not Found',
        message: 'Sorry, we could not find the form you were looking for',
      };
    }
    case 'IncidentNotFound': {
      return {
        type: 'Incident Not Found',
        message: 'Sorry, we could not find the incident you were looking for',
      };
    }
    case 'LabelNotFound':
    case 'ParticipantLabelNotFound': {
      return {
        type: 'Label Not Found',
        message: 'Sorry, we could not find the label you were looking for',
      };
    }
    case 'OrganizationNotFound':
    case 'UserOrganizationNotFound': {
      return {
        type: 'Organization Not Found',
        message: 'Sorry, we could not find the organization you were looking for',
      };
    }
    case 'ParticipantNotFound': {
      return {
        type: 'Participant Not Found',
        message: 'Sorry, we could not find the participant you were looking for',
      };
    }
    case 'ParticipantBehaviourRecordingNotFound': {
      return {
        type: 'Participant Behaviour Recording Not Found',
        message: 'Sorry, we could not find the recording you were looking for',
      };
    }
    case 'ParticipantBehaviourRecordingMethodNotFound': {
      return {
        type: 'Participant Behaviour Recording Method Not Found',
        message: 'Sorry, we could not find the recording method you were looking for',
      };
    }
    case 'IncidentFileNotFound':
    case 'ParticipantDocumentNotFound':
    case 'ParticipantDocumentVersionNotFound':
    case 'ParticipantFileNotFound': {
      return {
        type: 'File Not Found',
        message: 'Sorry, we could not find the file or document you were looking for',
      };
    }
    case 'ParticipantFileDuplicateName': {
      return {
        type: 'Duplicate File',
        message: 'Sorry, the filename you provided already exists, please choose another.',
      };
    }
    case 'ParticipantFieldNotFound':
    case 'ParticipantFieldTemplateNotFound': {
      return {
        type: 'Participant Field Not Found',
        message: 'Sorry, we could not find the participant field you were looking for',
      };
    }
    case 'ParticipantFieldGroupNotFound':
    case 'ParticipantFieldGroupTemplateNotFound': {
      return {
        type: 'Participant Field Group Not Found',
        message: 'Sorry, we could not find the participant field group you were looking for',
      };
    }
    case 'ParticipantExportError': {
      return {
        type: 'Export Error',
        message: 'Sorry, we could not export the data you were looking for',
      };
    }
    case 'ParticipantNoteVersionNotFound':
    case 'ParticipantNoteNotFound': {
      return {
        type: 'Note Not Found',
        message: 'Sorry, we could not find the note you were looking for',
      };
    }
    case 'ParticipantBehaviourNotFound': {
      return {
        type: 'Behaviour Not Found',
        message: 'Sorry, we could not find the behaviour you were looking for',
      };
    }
    case 'ParticipantQrNotFound': {
      return {
        type: 'QR Not Found',
        message: 'Sorry, we could not find the QR code you were looking for',
      };
    }
    case 'ParticipantUserNotFound': {
      return {
        type: 'User Not Found',
        message: 'Sorry, we could not find the team member you were looking for',
      };
    }
    case 'ParticipantKeyContactNotFound': {
      return {
        type: 'Key Contact Not Found',
        message: 'Sorry, we could not find the key contact you were looking for',
      };
    }
    case 'Auth0UserIdNotFound':
    case 'UserNotFound': {
      return {
        type: 'User Not Found',
        message: 'Sorry, we could not find the user you were looking for',
      };
    }
    case 'IncorrectTemplateType':
      return {
        type: 'Server Error',
        message:
          'Sorry, the selected template type cannot be used to create a document with this type.',
      };
    case 'ParticipantNotActive': {
      return {
        type: 'Participant Not Active',
        message: 'Sorry, this participant is not active and its actions have been disabled',
      };
    }
    case 'ParticipantBehaviourNotActive': {
      return {
        type: 'Behaviour Not Active',
        message: 'Sorry, this behaviour is not active and its actions have been disabled',
      };
    }
    case 'ParticipantBehaviourMethodNotActive': {
      return {
        type: 'Behaviour Method Not Enabled',
        message: 'Sorry, this behaviour method is not active and its actions have been disabled',
      };
    }
    case 'ParticipantBehaviourRecordingUserNotFound': {
      return {
        type: 'User Not Found',
        message: 'Sorry, we could not find the user associated with this recording',
      };
    }
    case 'ParticipantQrNotActive': {
      return {
        type: 'QR Not Active',
        message: 'Sorry, this QR code is not active and its actions have been disabled',
      };
    }
    case 'BillingActionNotFound':
    case 'BillingProductNotFound':
    case 'BillingUnprocessable':
    case 'BillingSubscriptionItemNotFound':
    case 'FormDecodeResultUnprocessable':
    case 'InternalClientError':
    case 'InternalServerError':
    case 'InvalidUserRoleCombination':
    case 'InvoiceInvalid':
    case 'ParticipantBehaviourMethodNotFound':
    case 'ParticipantQrBehaviourNotFound':
    case 'RoleNotFound':
    case 'StorageUploadInvalidDataType':
      return {
        type: 'Server Error',
        message: 'Sorry, something broke. Please contact support.',
      };
    default:
      // Will error when type has not been exhaustively matched
      P.exhaustiveMatchingGuard(error.type);
      return { type: 'Unknown Error', message: 'Unknown error occurred. Please contact support.' };
  }
};
