import { P } from '@piccolohealth/util';
import {
  Method,
  ParticipantBehaviour,
  ParticipantBehaviourStatus,
  ParticipantRecordingInfo,
  ParticipantStatus,
} from '../graphql';
import {
  PARTICIPANT_BEHAVIOUR_DISABLED_STATUSES,
  PARTICIPANT_DISABLED_STATUSES,
  PARTICIPANT_QR_DISABLED_STATUSES,
} from './constants';

export const isParticipantDisabled = (status: ParticipantStatus): boolean => {
  return PARTICIPANT_DISABLED_STATUSES.includes(status);
};

export const isBehaviourDisabled = (status: ParticipantBehaviourStatus): boolean => {
  return PARTICIPANT_BEHAVIOUR_DISABLED_STATUSES.includes(status);
};

export const isQrDisabled = (participant: ParticipantRecordingInfo): boolean => {
  return (
    !P.isNil(participant.qr) && PARTICIPANT_QR_DISABLED_STATUSES.includes(participant.qr.status)
  );
};

export const isRecordingDisabled = (participant: ParticipantRecordingInfo): boolean => {
  return isParticipantDisabled(participant.status) || isQrDisabled(participant);
};

export const getEnabledMethods = (participantBehaviour: ParticipantBehaviour): Method[] => {
  return Object.values(participantBehaviour.methods).filter(
    (method) => !P.isString(method) && method.enabled,
  ) as Method[];
};
