import { subject, Ability, AbilityBuilder, ForcedSubject } from '@casl/ability';
import { Role, User } from '../graphql/types';
export { subject };

export const SUBJECTS = [
  'all',
  'organization',
  'user',
  'behaviour',
  'form',
  'formLabel',
  'label',
  'location',
  'incident',
  'incidentFile',
  'incidentLabel',
  'participant',
  'participantBehaviour',
  'participantBehaviourRecordingMethod',
  'participantBehaviourRecording',
  'participantDocument',
  'participantDocumentComment',
  'participantDocumentLabel',
  'participantFieldGroupTemplate',
  'participantFieldTemplate',
  'participantField',
  'participantFile',
  'participantFileLabel',
  'participantNote',
  'participantNoteLabel',
  'participantLabel',
  'participantQr',
  'participantQrLabel',
  'participantUser',
  'participantUserLabel',
  'template',
  'templateLabel',
  'auditTrail',
] as const;

export const ACTIONS = ['manage', 'create', 'read', 'update', 'delete', 'list', 'review'] as const;

export type Roles = `${Role}`;
export type Actions = typeof ACTIONS[number];
export type Subjects = typeof SUBJECTS[number];

export type Subject = ReturnType<typeof subject<Exclude<Subjects, 'all'>, {}>>;

export type UserInfo = {
  user: User;
  roles: Roles[];
  organizationId?: string;
  participants?: string[];
  incidents?: {
    reviewer: string[];
    reporter: string[];
    all: string[];
  };
};

export type AppAbilities = [Actions, Subjects | ForcedSubject<Exclude<Subjects, 'all'>>];
export class AppAbility extends Ability<AppAbilities, any> {}

type DefinePermissions = (userInfo: UserInfo, builder: AbilityBuilder<AppAbility>) => void;

const rolePermissions: Record<Roles, DefinePermissions> = {
  Support: (userInfo, { can }) => {
    // Support can do anything
    can('manage', 'all');
  },

  Administrator: (userInfo, { can, cannot }) => {
    // Can do anything within their org
    can('manage', 'all', { organizationId: userInfo.organizationId });
    // Cannot create/delete organizations
    cannot(['create', 'delete'], 'organization');
  },

  TeamLeader: (userInfo, { can }) => {
    can(['read', 'list'], 'organization', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'behaviour', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'form', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'formLabel', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'label', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'location', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'template', { organizationId: userInfo.organizationId });

    can(['read', 'list'], 'user', { organizationId: userInfo.organizationId });
    can(['update'], 'user', { userId: userInfo.user.id });

    can(['list'], 'participant', { organizationId: userInfo.organizationId });
    can('manage', 'participantUser', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['read', 'update'], ['participant'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['read', 'list'], 'participantFieldGroupTemplate', {
      organizationId: userInfo.organizationId,
    });
    can(['read', 'list'], 'participantFieldTemplate', { organizationId: userInfo.organizationId });
    can(['manage'], 'participantDocument', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantDocumentComment', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantDocumentLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantFile', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantFileLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantNote', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantUserLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantNoteLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], ['participantQr'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], ['participantQrLabel'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], ['participantLabel'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });

    can('list', 'incident', { organizationId: userInfo.organizationId });
    can('create', 'incident', {
      organizationId: userInfo.organizationId ?? [],
    });
    can(['read', 'update'], 'incident', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.all ?? [],
      },
    });
    can('review', 'incident', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.reviewer ?? [],
      },
    });
    can('manage', 'incidentFile', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.all ?? [],
      },
    });
    can(['manage'], 'incidentLabel', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.all ?? [],
      },
    });
  },

  TeamMember: (userInfo, { can }) => {
    can(['read', 'list'], 'organization', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'behaviour', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'form', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'formLabel', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'label', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'location', { organizationId: userInfo.organizationId });
    can(['read', 'list'], 'template', { organizationId: userInfo.organizationId });

    can(['read', 'list'], 'user', { organizationId: userInfo.organizationId });
    can(['update'], 'user', { userId: userInfo.user.id });

    can(['list'], 'participant', { organizationId: userInfo.organizationId });
    can(['read', 'update'], ['participant'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['read', 'list'], 'participantFieldGroupTemplate', {
      organizationId: userInfo.organizationId,
    });
    can(['read', 'list'], 'participantFieldTemplate', { organizationId: userInfo.organizationId });
    can(['manage'], 'participantDocument', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantDocumentLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantFile', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantFileLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantNote', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], 'participantNoteLabel', {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], ['participantLabel'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], ['participantQr'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });
    can(['manage'], ['participantQrLabel'], {
      organizationId: userInfo.organizationId,
      participantId: {
        $in: userInfo.participants ?? [],
      },
    });

    can('list', 'incident', { organizationId: userInfo.organizationId });
    can('create', 'incident', {
      organizationId: userInfo.organizationId,
    });
    can(['read', 'update'], 'incident', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.all ?? [],
      },
    });
    can('manage', 'incidentFile', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.all ?? [],
      },
    });
    can(['manage'], 'incidentLabel', {
      organizationId: userInfo.organizationId,
      incidentId: {
        $in: userInfo.incidents?.all ?? [],
      },
    });
  },
};

export const defineAbilityFor = (userInfo: UserInfo): AppAbility => {
  const builder = new AbilityBuilder<AppAbility>(AppAbility);

  userInfo.roles.forEach((role) => {
    const permissionSet = rolePermissions[role];

    if (permissionSet) {
      permissionSet(userInfo, builder);
    }
  });

  return builder.build();
};
