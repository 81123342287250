import { Box, ChakraProvider, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { DescriptionList } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import { TopLevelFormatterParams } from 'echarts/types/dist/shared';
import React from 'react';
import { UserDescription } from '../../../../components/user/UserDescription';
import { theme } from '../../../../theme/theme';
import { RecordingUserData } from '../../utils';

interface Props {
  params: TopLevelFormatterParams;
  users: RecordingUserData;
}

export const ChartTooltip = (props: Props) => {
  const flatParams = P.flatten([props.params]);

  const first = flatParams[0];

  const data = first.data as Record<string, number | string>;
  const timestamp = data['timestamp'] as string;
  const formattedTimestamp = DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED);

  const items = React.useMemo(() => {
    return flatParams.flatMap((item) => {
      const data = item.data as Record<string, number | string>;
      const marker = item.marker as string;

      const seriesName = item.seriesName;
      const seriesIndex = item.seriesIndex;

      if (P.isNil(seriesName) || P.isNil(seriesIndex)) {
        return [];
      }

      const seriesId = Object.keys(data ?? {})[seriesIndex + 1];
      const seriesValue = Object.values(data ?? {})[seriesIndex + 1];

      if (P.isNil(seriesId) || P.isNil(seriesValue)) {
        return [];
      }

      const users = props.users[timestamp][seriesId] ?? [];

      return {
        marker,
        seriesName,
        seriesValue,
        users,
      };
    });
  }, [flatParams, props.users, timestamp]);

  const users = React.useMemo(() => {
    return P.uniqBy(
      items.flatMap((item) => item.users),
      (u) => u.email,
    );
  }, [items]);

  if (!first) {
    return null;
  }

  if (P.isEmpty(items)) {
    return null;
  }

  return (
    <ChakraProvider theme={theme}>
      <Stack>
        <Text fontWeight="semibold">{formattedTimestamp}</Text>
        <DescriptionList
          items={items.map(({ marker, seriesName, seriesValue }) => ({
            title: (
              <HStack>
                <Box dangerouslySetInnerHTML={{ __html: marker }} />
                <Text color="secondary">{seriesName}</Text>
              </HStack>
            ),
            content: <Text fontWeight="semibold">{seriesValue}</Text>,
          }))}
          rowGap={0}
        />
        {!P.isEmpty(users) && (
          <>
            <Divider />
            <Stack>
              <Text fontWeight="semibold" color="secondary">
                Recorded by
              </Text>
              {users.map((user) => (
                <UserDescription
                  key={user.email}
                  name={user.name}
                  secondary={user.email}
                  picture={user.__typename === 'User' ? user.picture : undefined}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </ChakraProvider>
  );
};
